import * as React from "react";
import * as style from "./Header.style.module.scss";

const Header = ({ page, children }) => {
  return (
    <header className={style.header}>
      <div className={style.introduction}>{page.introduction}</div>
      <h1 className={style.title}>{page.header}</h1>
      <p className={style.description}>{page.description}</p>
      {children}
    </header>
  );
};

export default Header;
