import * as React from "react";
import Layout from "../components/layout/Layout.component";
import Header from "../components/header/Header.component";
import InternalLink from "../components/card-list/internal-link/InternalLink.component";

const Page404 = () => {
  const page = {
    introduction: "You must be lost",
    header: "Page 404",
    description: "Go back to the home page",
  };
  return (
    <Layout pageTitle="404">
      <Header page={page}>
        <InternalLink path='/'>GO to home page</InternalLink>
      </Header>
    </Layout>
  );
};
export default Page404;

export function Head() {
  return <title>404</title>;
}