import * as React from "react";
import { Link } from "gatsby";
import * as style from "./InternalLink.style.module.scss";

const InternalLink = ({path, children}) => {
  return (
    <nav className={style.internal_link}>
      <Link to={`${path}`}>{children}</Link>
    </nav>
  );
};

export default InternalLink;
